<template>
    <div class="px-2 sm:px-10 py-4">
        <div class="flex justify-between items-center" v-if="venture">
            <div class="flex items-center space-x-4">
                <Text
                    v-if="!isEditName"
                    color="black"
                    weight="bold"
                    size="xl"
                    class="sm:text-3xl"
                    :content="venture && venture.name"
                />
                <Input
                    id="venture-name"
                    v-else
                    @keyup.enter="() => onPressEnter(true)"
                    @blur="() => onSave(true)"
                    v-model:value="venture.name"
                    custom-class="text-black font-bold text-xl sm:text-3xl w-full"
                />
                <Text
                    @click="isEditName = true"
                    size="sm"
                    color="primary-600"
                    content="edit"
                    decoration="underline"
                    custom-class="cursor-pointer"
                />
            </div>
            <Select
                class="mr-2 w-56"
                :items="options"
                v-model:selected-item="selectedOption"
            />
        </div>
        <div
            class="flex justify-between items-center space-x-2 mt-2"
            v-if="venture?.id"
        >
            <Text
                v-if="!isEditDesc"
                color="black"
                weight="semibold"
                size="sm"
                :content="venture && venture.description"
            />
            <Input
                id="venture-description"
                v-else
                @keyup.enter="() => onPressEnter(false)"
                @blur="() => onSave(false)"
                v-model:value="venture.description"
                custom-class="text-black font-semibold text-sm w-full"
            />
            <Text
                @click="isEditDesc = true"
                size="sm"
                color="primary-600"
                content="edit"
                decoration="underline"
                custom-class="cursor-pointer"
            />
        </div>
        <div class="mt-10">
            <div class="flex items-center space-x-5">
                <Text
                    color="black"
                    weight="bold"
                    size="2xl"
                    customClass="sm:text-2xl"
                    :content="content.research.title"
                />
                <Badge
                    :label="content.research.badge"
                    px="3"
                    py="1"
                    bg-color="primary-500"
                    custom-class="rounded-full"
                    text-color="white"
                    text-weight="semibold"
                />
            </div>
            <Text
                size="xs"
                color="black"
                :content="content.research.description"
                custom-class="mt-2"
            />
            <EmptyState
                v-if="targetCustomers?.length === 0"
                class="bg-white mt-3"
                isFull
                v-bind="{
                    title: '',
                    description: 'No research progress to display just yet!',
                }"
            />
            <Table
                @onClickTargetCustomer="
                    (id) =>
                        $router.push(
                            `/venture/${state.currentVenture?.id}/target-customers/${id}`
                        )
                "
                :list="targetCustomers"
                class="mt-5"
            />
        </div>
        <div class="mt-10">
            <div class="flex items-center space-x-5">
                <Text
                    color="black"
                    weight="bold"
                    size="2xl"
                    customClass="sm:text-2xl"
                    :content="content.venture.title"
                />
                <Text
                    @click="
                        () =>
                            $router.push(
                                `/venture/${state.currentVenture?.id}/settings`
                            )
                    "
                    size="sm"
                    color="primary-600"
                    :content="content.venture.btnText"
                    decoration="underline"
                    custom-class="cursor-pointer"
                />
            </div>
            <EmptyState
                v-if="ventureTeams?.length === 0"
                class="bg-white mt-3"
                isFull
                v-bind="{
                    title: '',
                    description: 'No venture team to display just yet!',
                }"
            />
            <Table
                v-if="ventureTeams?.length > 0"
                :list="ventureTeams"
                class="mt-3 mb-5"
            />
        </div>
    </div>
    <DeleteModal
        :is-show="isOpenRemove"
        @onClose="() => ((isOpenRemove = false), (removeOption = null))"
        :title="
            removeOption && removeOption === 'removeFromEcosystem'
                ? `<b>Remove from ecosystmn?</b>`
                : `<b>Remove from cohort?</b>`
        "
        :description="`Are you sure you want to remove this <b>Venture Team</b> from your ${
            removeOption && removeOption == 'removeFromEcosystem'
                ? 'ecosystem'
                : 'cohort'
        }?`"
        yes-btn-text="Remove"
        no-btn-text="Cancel"
        icon="ExclamationIcon"
        yesBtnVariant="danger"
        noBtnVariant="secondary"
        :on-no-click="() => ((isOpenRemove = false), (removeOption = null))"
        :on-yes-click="() => removeFromEcosytem()"
    />
</template>

<script>
import Input from "../components/molecules/Inputs/Component/Component";
import EmptyState from "../components/organisms/EmptyState/Simple/Simple";
import ListViewHeader from "../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton";
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import Table from "../components/organisms/Tables/Simple/Simple.vue";
import Text from "../components/atoms/Text/Text";
import { c } from "../components/constants.js";
import InviteMember from "../components/organisms/Modals/InviteMember/InviteMember.vue";
import Badge from "../components/molecules/Badge/Basic/Basic";
import Select from "../components/molecules/Selects/Component/Component.vue";
import DeleteModal from "../components/organisms/Modals/SimpleAlert/SimpleAlert";
import { get } from "lodash";
import { _ } from "vue-underscore";

export default {
    components: {
        EmptyState,
        ListViewHeader,
        CardWithImage,
        CardWithBadge,
        Text,
        InviteMember,
        Table,
        Input,
        Badge,
        Select,
        DeleteModal,
    },
    data() {
        return {
            content: c.range,
            isShow: false,
            isOpenTip: true,
            isLoading: false,
            get,
            c,
            venture: null,
            resources: [],
            isEditName: false,
            isEditDesc: false,
            selectedOption: { id: 1, name: "Options", value: "options" },
            isOpenRemove: false,
            removeOption: null,
        };
    },
    methods: {
        onPressEnter(isName) {
            if (isName) {
                document.getElementById("venture-name").blur();
            } else {
                document.getElementById("venture-description").blur();
            }
        },
        async onSave(isName) {
            this.isEditDesc = false;
            this.isEditName = false;
            await this.actions.venture.saveVenture({
                where: { id: this.$route.params?.id },
                data: isName
                    ? { name: this.venture?.name }
                    : { description: this.venture?.description },
            });
            const ventures = await this.actions.venture.getVentures({
                where: { id: this.$route.params?.id },
                getValues: true,
                all: true,
                query: "ventureDetail",
            });
            this.venture = ventures[0];
        },
        async onInitialize() {
            const ventures = await this.actions.venture.getVentures({
                where: { id: this.$route.params?.id },
                getValues: true,
                all: true,
                query: "ventureDetail",
            });
            this.venture = ventures[0];
            // this.actions.setCurrentVenture(this.venture);
        },
        async removeFromEcosytem() {
            this.isOpenRemove = false;
            if (this.removeOption === "removeFromEcosystem") {
                const { purgeVenture } =
                    await this.actions.venture.purgeVenture({
                        ventureId: this?.venture?.id,
                    });
                if (purgeVenture) {
                    this.actions.alert.showSuccess({
                        message:
                            "Venture team removed from ecosystem successfully!",
                    });
                    this.$router.go(-1);
                }
            } else if (this.removeOption === "removeFromCohort") {
                var cohortIds = [];
                if (this.$route?.query?.program) {
                    cohortIds = this?.venture?.cohorts
                        ?.filter(
                            (cohort) =>
                                cohort?.program?.id ===
                                this.$route?.query?.program
                        )
                        .map((item) => {
                            return { id: item?.id };
                        });
                } else if (this.$route?.query?.cohort) {
                    cohortIds = [{ id: this.$route?.query?.cohort }];
                } else {
                    cohortIds = this?.venture?.cohorts?.map((cohort) => {
                        return { id: cohort?.id };
                    });
                }
                if (cohortIds.length > 0) {
                    await this.actions.venture.saveVenture({
                        data: { cohorts: { disconnect: cohortIds } },
                        where: { id: this?.venture?.id },
                    });
                    const ventures = await this.actions.venture.getVentures({
                        where: { id: this.$route.params?.id },
                        getValues: true,
                        query: "ventureDetail",
                    });
                    this.venture = ventures[0];
                    this.actions.alert.showSuccess({
                        message:
                            "Venture team removed from cohort successfully!",
                    });
                }
            }
            this.removeOption = null;
            this.selectedOption = { id: 1, name: "Options", value: "options" };
        },
    },
    computed: {
        options() {
            const data = [
                { id: 1, name: "Options", value: "options" },
                {
                    id: 2,
                    name: "Remove from Cohort",
                    value: "removeFromCohort",
                },
                {
                    id: 3,
                    name: "Remove from Ecosystem",
                    value: "removeFromEcosystem",
                },
            ];
            if (this.$route?.query?.program) {
                const cohort = this?.venture?.cohorts?.find((cohort) => {
                    return cohort?.program?.id === this.$route?.query?.program;
                });
                cohort && cohort?.id
                    ? (data[1].name = "Remove from all Cohorts")
                    : data.splice(1, 1);
            } else if (this.$route?.query?.cohort) {
                const cohort = this?.venture?.cohorts?.filter(
                    (cohort) => cohort?.id === this.$route?.query?.cohort
                );
                if (!cohort) data.splice(1, 1);
            } else {
                this?.venture?.cohorts?.length > 0
                    ? (data[1].name = "Remove from all Cohorts")
                    : data.splice(1, 1);
            }
            return data;
        },
        targetCustomers() {
            const data = [];
            if (
                this?.venture?.targetCustomerUsers?.filter(
                    (t) => !t?.isArchived
                )
            ) {
                [
                    ...this.venture.targetCustomerUsers?.filter(
                        (t) => !t?.isArchived
                    ),
                ].map((customer) => {
                    let totalOpportunities = 0,
                        totalSurveys = 0,
                        totalCompletedSurveys = 0;
                    customer?.interviews
                        ?.filter((o) => !o?.isArchived)
                        ?.map(
                            (interview) =>
                                (totalOpportunities +=
                                    interview?.opportunities?.filter(
                                        (o) => !o?.isArchived
                                    )?.length)
                        );
                    customer?.surveys?.map((survey) => {
                        totalSurveys += survey?.userSurveys?.length;
                        totalCompletedSurveys += survey?.userSurveys?.filter(
                            (userSurvey) => userSurvey.isCompleted === true
                        )?.length;
                    });
                    data.push({
                        "Target Customer": customer?.name || "",
                        Opportunities:
                            customer?.opportunities?.filter(
                                (o) => !o?.isArchived
                            )?.length || 0,
                        Contacts:
                            customer?.contacts?.filter((o) => !o?.isArchived)
                                ?.length || 0,
                        "Scheduled Interviews":
                            customer?.interviewScripts?.length || 0,
                        "Completed Interviews":
                            totalOpportunities +
                            " / " +
                            (customer?.interviews?.filter((o) => !o?.isArchived)
                                ?.length || 0),
                        "Survey Responses":
                            totalCompletedSurveys + " / " + totalSurveys,
                    });
                });
            }
            return data;
        },
        ventureTeams() {
            const team = [];
            if (this?.venture?.users) {
                [...this.venture.users].map((user) => {
                    team.push({
                        name: user.fullName,
                        role: user?.groups?.[0]?.name || "",
                        contacts: user?.contactRequests?.length || 0,
                        interviews: 0,
                        Opportunities:
                            user?.opportunities?.filter((o) => !o?.isArchived)
                                ?.length || 0,
                    });
                });
            }
            return team;
        },
    },
    async created() {
        await this.onInitialize();
        if (this.state.group?.groups?.length === 0) {
            await this.actions.group.getGroups();
        }
    },
    watch: {
        selectedOption(val) {
            if (val && val.id !== 1) {
                this.isOpenRemove = true;
                this.removeOption = val?.value;
            }
        },
    },
};
</script>
